import * as React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }

const EmailField = React.forwardRef<HTMLInputElement, CustomProps>(
    function EmailField(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
            {...other}
            mask={/^\S*@?\S*$/}
            placeholder="example@example.com"
            inputRef={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
      />
      );
    },
  );

export default EmailField;