import * as React from 'react';
import { styled, Theme } from '@mui/system';
import AppFooter from './modules/views/AppFooter';
import HomeHero from './modules/views/HomeHero';
import PricingDetails from './modules/views/PricingDetails';
import AppAppBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';
import ContactForm from './modules/views/ContactForm';
import { Box } from '@mui/material';

const Background = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(https://www.mkloggingandlumber.com/wp-content/uploads/2020/07/IMG_1109-jpg-1.jpg)`,
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.5)', // Change the last value to adjust the opacity of the overlay
  },
});


const Content = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '80%',
  },
}));

const Index = () => {
  const pricingRef = React.useRef<HTMLDivElement>(null);
  const contactRef = React.useRef<HTMLDivElement>(null);
  const pricingLinkRef = React.useRef<HTMLAnchorElement>(null);
  const contactLinkRef = React.useRef<HTMLAnchorElement>(null);

  const [isPricingInView, setPricingInView] = React.useState(false);
  const [isContactInView, setContactInView] = React.useState(false);

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold as needed
    };
  
    const pricingObserver = new IntersectionObserver(([entry]) => {
      setPricingInView(entry.isIntersecting);
    }, options);
  
    const contactObserver = new IntersectionObserver(([entry]) => {
      setContactInView(entry.isIntersecting);
    }, options);
    
    if (pricingRef.current) {
      pricingObserver.observe(pricingRef.current);
    }
    if (contactRef.current) {
      contactObserver.observe(contactRef.current);
    }
  
    // Cleanup observers on component unmount
    return () => {
      pricingObserver.disconnect();
      contactObserver.disconnect();
    };
  }, []);

  const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href');
    const targetSection = document.querySelector(targetId as string);
    targetSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <React.Fragment>
      <AppAppBar
        pricingLinkRef={pricingLinkRef}
        contactLinkRef={contactLinkRef}
        isPricingInView={isPricingInView}
        isContactInView={isContactInView}
        handleNavClick={handleNavClick}
      />
      <HomeHero
        contactRef={contactRef}
        pricingRef={pricingRef}
        handleNavClick={handleNavClick}
      />
      <Background>
        <Content>
          <Box id="pricing" ref={pricingRef}>
            <PricingDetails />
          </Box>
          <Box id="contact" ref={contactRef}>
            <ContactForm />
          </Box>
        </Content>
      </Background>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);