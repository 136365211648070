import axios from "axios";
import { CLIENT_ID } from "../consts";
import { SubmitDataInterface } from "../interfaces";

interface SubmitFormArgs {
    apiRoute: string;
    data: SubmitDataInterface;
    headers?: object;
}

const submitForm = async ({ apiRoute, headers, data }: SubmitFormArgs): Promise<String> => {
    const body = {
        clientID: CLIENT_ID,
        form: data
    }
    headers = { 'Content-Type': 'application/json', ...headers};
    return await axios
        .post(apiRoute, body, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return "";
        });
}

export default submitForm;