import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import wood1 from '../../static/wood1.jpg';
import wood2 from '../../static/wood2.jpg';
import useScreenSize from '../../common/util/useScreenSize';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  px: 5,
};

function PricingDetails() {
  const screenSize = useScreenSize();

  const imageHeight = ['xs', 'sm'].includes(screenSize) ? '100%' : '90%';
  const imageWidth = ['xs', 'sm'].includes(screenSize) ? '100%' : '90%';

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 10, display: 'flex', position: 'relative', flexDirection: 'column' }}>
        <Typography variant="h4" sx={{ my: 2 }}>
            Details &amp; Pricing
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box sx={item}>
              <Typography variant="h6" sx={{ my: 2 }}>
                Firewood
              </Typography>
              <Typography variant="h5">
              Stock pile is cut at 16" and split to a burnable size. All firewood is 100% hardwood (no pine) consisting of red and white oak, maple, hickory, cherry, locust, black birch, and ash.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <Typography variant="h6" sx={{ my: 2 }}>
                Prices
              </Typography>
              <List dense={true}>
                <ListItem>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText
                    primary="$275 for one cord"
                    primaryTypographyProps={{ variant: 'h5', align: 'left' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText
                    primary="$250 for 2 cords or more"
                    primaryTypographyProps={{ variant: 'h5' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText
                    primary="Outdoor furnance wood: $225 per cord (2 cord minimum)"
                    primaryTypographyProps={{ variant: 'h5' }}
                  />
                </ListItem>
            </List>
            </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={wood1} alt="Firewood pile at Blue Mountain Firewood in Walnutport, PA" style={{ width: imageWidth, height: imageHeight, border: 0 }} />
            </Grid>
            <Grid item xs={12}  md={6} order={['xs', 'sm'].includes(screenSize) ? 2 : 1}>
                <img src={wood2} alt="A firewood pile with a sun reflection at Blue Mountain Firewood in Walnutport, PA" style={{ width: imageWidth, height: imageHeight, border: 0 }} />
            </Grid>
          <Grid item xs={12} md={6} order={['xs', 'sm'].includes(screenSize) ? 1 : 2}>
            <Box sx={item}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Pricing Details
              </Typography>
              <List dense={true}>
                <ListItem>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText
                    primary="Custom cut cords are available upon request, contact Austen for pricing."
                    primaryTypographyProps={{ variant: 'h5', align: 'left' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Lengths vary from 12"-24"."`}
                    primaryTypographyProps={{ variant: 'h5' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText
                    primary="3 cords of firewood can be hauled per trip."
                    primaryTypographyProps={{ variant: 'h5' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText
                    primary="*All cords are measured at 190 cubic feet loose thrown"
                    primaryTypographyProps={{ variant: 'h5' }}
                  />
                </ListItem>
            </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default PricingDetails;
