import * as React from 'react';
import { SelectFieldProps } from './interfaces';
import { MenuItem, TextField } from '@mui/material';

export default function SelectField({ options = [], id, label, defaultValue = "", ...allOtherProps } : SelectFieldProps) {
    return (
        <TextField
            fullWidth
            variant="outlined"
            id={id}
            label={label}
            select
            {...allOtherProps}
            name={id}
            defaultValue={defaultValue}
        >
        {options.map((item, i) => (
            <MenuItem key={`menu-item-${id}-${i}`} value={item.value}>
                {item.label}
            </MenuItem>
        ))}   
        </TextField>
  );
}