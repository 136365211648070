import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import useScreenSize from '../../common/util/useScreenSize';
import CutSplitDeliveryImage from "../../static/cut-split-delivery-yard.jpg";

interface HomeHeroProps {
  contactRef: React.RefObject<HTMLDivElement>;
  pricingRef: React.RefObject<HTMLDivElement>;
  handleNavClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export default function HomeHero({ contactRef, pricingRef, handleNavClick }: HomeHeroProps) {
  const screenSize = useScreenSize();
  
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundColor: '#7fc7d9',
        backgroundPosition: 'center',
      }}
    >
      {screenSize !== undefined && ['xs', 'sm'].includes(screenSize) ? 
        <img
          src={CutSplitDeliveryImage}
          alt="Cut Split Delivery" 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
            zIndex: -1,
          }}
          />  : 
        <iframe 
          title="Video Header for Blue Mountain Firewood" 
          src="https://www.youtube.com/embed/jo6tDCptPDQ?controls=0&showinfo=0&rel=0&autoplay=1&loop=0&mute=1"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
            zIndex: -1,
          }}
      />
      }
        <Typography color="inherit" align="center" variant="h2" marked="center">
          Cut &amp; Split Firewood
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          sx={{ mb: 4, mt: { xs: 4, sm: 5 } }}
        >
          Delivery Within 15 Mile Radius of Walnutport, PA. Pickup Available upon request.
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          href="#contact"
          sx={{ minWidth: 200 }}
          onClick={handleNavClick}
        >
          Contact Us
        </Button>
    </ProductHeroLayout>
  );
}