import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import Typography from '../components/Typography';
import useScreenSize from '../../common/util/useScreenSize';

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

interface AppAppBarProps {
  pricingLinkRef: React.RefObject<HTMLAnchorElement>;
  contactLinkRef: React.RefObject<HTMLAnchorElement>;
  isPricingInView: boolean;
  isContactInView: boolean;
  handleNavClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}


function AppAppBar({ pricingLinkRef, contactLinkRef, isPricingInView, isContactInView, handleNavClick }: AppAppBarProps) {
  const screenSize = useScreenSize();

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant={screenSize !== undefined && ['xs', 'sm'].includes(screenSize) ? 'h6' : 'h1'} component='h1' color='white'>
            Blue Mountain Firewood
          </Typography>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="#pricing"
              sx={{ ...rightLink, color: isPricingInView ? 'secondary.main' : 'common.white' }}
              ref={pricingLinkRef}
              onClick={handleNavClick}
            >
              Prices
            </Link>
            <Link
              variant="h6"
              underline="none"
              href="#contact"
              sx={{ ...rightLink, color: isContactInView && !isPricingInView ? 'secondary.main' : 'common.white' }}
              ref={contactLinkRef}
              onClick={handleNavClick}
            >
              Contact
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
