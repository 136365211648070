import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { Link } from '@mui/material';
import useScreenSize from '../../common/util/useScreenSize';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
        Blue Mountain Firewood {' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

function WebsiteDesignedBy() {
  const screenSize = useScreenSize();
  return (
    <React.Fragment>
      {`Website Designed and Maintained by ${screenSize !== undefined && screenSize === 'xs' ? '' : '\u00A0'}`}
        <Link href="https://michaelbartlett.me" target="_blank" rel="noopener noreferrer" display="inline" sx={{ color: "white" }}>
          Michael Bartlett
        </Link>
    </React.Fragment>
  );
}

export default function AppFooter() {
  const screenSize = useScreenSize();

  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', backgroundColor: '#976e53', color: "white" }}
    >
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={screenSize !== undefined && screenSize === 'xs' ? 0 : 5}>
          <Grid item xs={6}>
            <Copyright />
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <WebsiteDesignedBy />
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
