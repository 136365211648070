import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert, AlertTitle, Backdrop, Box, Button, CircularProgress, Container, Link, TextField } from '@mui/material';
import SelectField from '../fields/SelectField';
import { OptionsInterface } from '../fields/interfaces';
import Typography from '../components/Typography';
import PhoneField from '../fields/PhoneField';
import EmailField from '../fields/EmailField';
import { SubmitDataInterface } from '../../common/interfaces';
import submitForm from '../../common/util/submitForm';
import { CONTACT_FORM_API_KEY, CONTACT_FORM_API_ROUTE } from '../../common/consts';
import { isEmail, isPhoneNumber } from '../form/validation';
import wood3 from '../../static/wood3.jpg';

const productOptions: OptionsInterface[] = [
    { label: '16" Cut & Split Firewood', value: '16" Cut & Split Firewood' },
    { label: 'Custom Sized Cut & Split Firewood', value: 'Custom Sized Cut & Split Firewood' }
];

const bestTimeOptions: OptionsInterface[] = [
    { label: 'Morning', value: 'Morning' },
    { label: 'Afternoon', value: 'Afternoon' },
    { label: 'Evening', value: 'Evening' }
];

function SubmitSuccess() {
    return (
        <Box
            component="section"
            sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
        >
            <Container sx={{ mt: 5, mb: 15, display: 'flex', position: 'relative', flexDirection: 'column' }}>
                <Alert severity="success">
                    <AlertTitle>Thank you for your form submission!</AlertTitle>
                    Austen will get back in touch as soon as possible.
                </Alert>
            </Container>
        </Box>
    );
}

export default function ContactForm() {
    const [submitSuccess, setSubmitSuccess] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);
    const [recaptchaError, setRecaptchaError] = React.useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setEmailError(false);
        setPhoneError(false)

        setRecaptchaError(false);

        const form = e.currentTarget;
        const submitData: SubmitDataInterface = {};
        for (const element of form.elements as any) {
            if (element.id || element.name) {
                const id = element.id || element.name;
                const labelElement = document.querySelector(`label[for="${id}"]`);
                const label = labelElement?.textContent ?? '';
                submitData[id] = { label: label.replace("*", "").trim(), value: element.value };
            }
        }
        if (submitData.email.value && !isEmail(submitData.email.value)) {
            setEmailError(true);
            return;
        }
        if (submitData.phone.value && !isPhoneNumber(submitData.phone.value)) {
            setPhoneError(true);
            return;
        }
        if (submitData["g-recaptcha-response"]) {
            submitData.recaptcha = { label: "recaptcha", value: submitData["g-recaptcha-response"].value };
            delete submitData["g-recaptcha-response"];
        }
        if (!submitData?.recaptcha?.value) {
            setRecaptchaError(true);
            return;
        }
        setSubmitting(true);
        const headers = { 'x-api-key': CONTACT_FORM_API_KEY };
        const res = await submitForm({ apiRoute: CONTACT_FORM_API_ROUTE, data: submitData, headers });
        setSubmitting(false);
        if (res === "OK") {
            setSubmitSuccess(true);
            setTimeout(() => {
                setSubmitSuccess(false);
            }, 10000);
        }
    }

    if (submitSuccess) {
        return (
            <SubmitSuccess />
        )
    }

    return (
        <Box
            component="section"
            sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
        >
            <Container component="form" onSubmit={handleSubmit} sx={{ mt: 5, mb: 15, display: 'flex', position: 'relative', flexDirection: 'column' }}>
                <Backdrop
                    sx={{ color: '#fff' }}
                    open={submitting}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
                <Typography variant="h4" sx={{ my: 5 }}>
                    Contact Us
                </Typography>
                <Typography variant="h5" sx={{ mb: 5 }}>
                    Contact Austen Kleintop for Cut &amp; Split Firewood
                    <br />
                    Call him directly: <Link href="tel:6105544298">(610) 554-4298.</Link> Or fill out the form below. He will get back to you as soon as possible!
                </Typography>
                <Box mb={2}>
                    <TextField id='name' label='Name' required={true} variant='outlined' fullWidth />
                </Box>
                <Box mb={2}>
                    <TextField id='address' label='Address for Delivery?' required={false} variant='outlined' fullWidth />
                </Box>
                <Box mb={2}>
                    <TextField id='phone' label='Your Phone Number' required={true} variant='outlined' fullWidth InputProps={{ inputComponent: PhoneField as any}} error={phoneError} helperText={phoneError ? "Invalid Phone Number" : null} />
                </Box>
                <Box mb={2}>
                    <TextField id='email' label='Email' required={true} variant='outlined' fullWidth InputProps={{ inputComponent: EmailField as any}} error={emailError} helperText={emailError ? "Invalid Email" : null} />
                </Box>
                <Box mb={2}>
                    <SelectField id='product' label='Product Needed' required={true} variant='outlined' fullWidth options={productOptions} />
                </Box>
                <Box mb={2}>
                    <SelectField id='bestTime' label='Best Time to Reach You' required={true} variant='outlined' fullWidth options={bestTimeOptions} />
                </Box>
                <Box mb={2}>
                <ReCAPTCHA sitekey="6LcdSlkpAAAAACSwjcht0kkSf4jTN-6qLKenl0hB" />
                {recaptchaError && <Typography variant="body1" color="error">Please complete the reCAPTCHA</Typography>}
                </Box>
                <Button variant="contained" color="secondary" type="submit">
                    Submit
                </Button>
                <img src={wood3} alt="Firewood pile during sundown at Blue Mountain Firewood in Walnutport, PA" style={{ width: '100%', height: '100%', border: 0 }} />
            </Container>
        </Box>
    );
}